<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard class="topCard">
          <CCardHeader class="topCardHeader">
            <div class="d-flex justify-content-between align-items-center">
              <div class="h4 m-0">Yöneticiler</div>
              <div class="d-flex justify-content-end" style="min-width: 550px">
                <input type="text" v-model="searchFilterInput" class="form-control mr-3" style="max-width: 250px"
                  autocomplete="new" placeholder="Arama..." v-on:keyup="onFilterTextBoxChanged()" />
                <RoleProvider slug="ADMIN_CREATE">
                  <div slot-scope="{ canItPass }">
                    <CButton color="light" variant="outline" v-if="canItPass" size="sm" @click="
                      cleanAdminModalAddUpdateForm(),
                      (adminModalAddUpdate.process = 'post'),
                      (adminModalAddUpdate.title = 'Yeni Yönetici Ekle'),
                      (adminModalAddUpdate.modal = true)
                    " class="py-2 px-3 font-weight-bold">
                      <font-awesome-icon :icon="['fas', 'plus']" class="mr-2" />Yeni Ekle
                    </CButton>
                  </div>
                </RoleProvider>
              </div>
            </div>
          </CCardHeader>

          <CCardBody class="p-0">
            <!-- :sendToClipboard="sendToClipboard" -->
            <ag-grid-vue style="width: 100%; height: calc(100vh - 145px)" class="ag-theme-balham fp-table"
              :columnDefs="columnDefs" :defaultColDef="defaultColDef" :rowData="rowData" :sideBar="sideBar"
              :rowSelection="rowSelection" :enableRangeSelection="true" :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked" @grid-ready="onGridReady"></ag-grid-vue>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal :title="adminModalAddUpdate.title" size="lg" :closeOnBackdrop="false" :show.sync="adminModalAddUpdate.modal"
      class="vendorUserModalAddUpdateForm modalNoBorder">
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="4">
                <CInput type="text" label="Ad" placeholder="Ad girin" v-model="adminModalAddUpdate.form.name" />
              </CCol>
              <CCol md="4">
                <CInput type="text" label="Soyad" placeholder="Soyad girin" v-model="adminModalAddUpdate.form.lastname" />
              </CCol>
              <CCol md="4">
                <CInput type="text" label="Kullanıcı Adı" placeholder="Kullanıcı adı girin"
                  v-model="adminModalAddUpdate.form.username" />
              </CCol>
              <CCol md="4">
                <CInput type="text" label="E-Posta Adresi" placeholder="E-posta adresi girin"
                  v-model="adminModalAddUpdate.form.email" />
              </CCol>
              <CCol md="4" v-if="adminModalAddUpdate.process == 'post'">
                <CInput type="password" autocomplete="new-password" label="Şifre" placeholder="Şifre girin"
                  v-model="adminModalAddUpdate.form.password" />
              </CCol>
              <CCol md="4">
                <CSelect label="Durum" :placeholder="
                  adminModalAddUpdate.process == 'post'
                    ? 'Seçiniz'
                    : adminModalAddUpdate.form.status
                " :value.sync="adminModalAddUpdate.form.status" :options="adminModalAddUpdate.fields.status" />
              </CCol>
              <CCol md="4">
                <CSelect :placeholder="
                  adminModalAddUpdate.process == 'post'
                    ? 'Seçiniz'
                    : adminModalAddUpdate.form.role
                " label="Grup Yetkilendirme" :value.sync="adminModalAddUpdate.form.role"
                  :options="adminModalAddUpdate.fields.role" />
              </CCol>
              <CCol md="4">
                <CSelect :placeholder="
                  adminModalAddUpdate.process == 'post'
                    ? 'Seçiniz'
                    : adminModalAddUpdate.form.departmanRole
                " label="Departman Rolü" :value.sync="adminModalAddUpdate.form.departmanRole"
                  :options="adminModalAddUpdate.fields.departmanRole" />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <RoleProvider slug="ADMIN_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton color="danger" v-if="adminModalAddUpdate.process === 'put' && canItPass"
              @click="adminDelete(adminModalAddUpdate.form._id)">Sil</CButton>
          </div>
        </RoleProvider>

        <RoleProvider slug="ADMIN_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton color="warning" v-if="adminModalAddUpdate.process === 'put' && canItPass"
              @click="adminModalUpdatePassword.modal = true">Şifre Değiştir</CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="ADMIN_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton color="success" v-if="adminModalAddUpdate.process === 'put' && canItPass"
              :disabled="adminModalAddUpdate.btnDisable" @click="adminAddUpdate()">Düzenle</CButton>
          </div>
        </RoleProvider>
        <RoleProvider slug="ADMIN_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton color="success" v-if="adminModalAddUpdate.process === 'post' && canItPass"
              :disabled="adminModalAddUpdate.btnDisable" @click="adminAddUpdate()">Ekle</CButton>
          </div>
        </RoleProvider>
      </template>
    </CModal>

    <!-- :closeOnBackdrop="false" -->
    <CModal :title="adminModalUpdatePassword.title" :closeOnBackdrop="false" :show.sync="adminModalUpdatePassword.modal">
      <CRow>
        <CCol md="12">
          <CInput type="password" autocomplete="new-password" label="Yeni Şifre" placeholder="Şifre girin"
            v-model="adminModalUpdatePassword.password" />
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="success" :disabled="adminModalUpdatePassword.btnDisable" @click="adminPassword()">Değiştir
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
import { RoleProvider } from "../../provider";
var _ = require("lodash");
Vue.use(VueSweetalert2);

const ADMIN_STATUS = {
  Active: {
    value: "Aktif",
    style: { color: "#2eb85c" },
  },
  InActive: {
    value: "Pasif",
    style: { color: "#1EA7E5" },
  },
  Pending: {
    value: "Bekliyor",
    style: { color: "#E2E51E" },
  },
  Banned: {
    value: "Engellendi",
    style: { color: "#E51E1E" },
  },
  Deleted: {
    value: "Silindi",
    style: { color: "#E51E1E" },
  },
};
const ADMIN_DEFAULT_ROLES = {
  SUPERUSER: {
    value: "Tam Yetki",
  },
  PARTNER: {
    value: "Partner",
  },
  AGENCY: {
    value: "Acenta",
  },
};


export default {
  name: "Admins",
  components: {
    AgGridVue,
    RoleProvider,
  },
  options: { ADMIN_STATUS },
  data() {
    return {
      searchFilterInput: null,
      // AG GRID
      columnDefs: [
        // Grid sütünların dizisi
        {
          field: "status",
          cellRenderer: (params) =>
            ADMIN_STATUS[params.value]?.value || "Belirsiz",
          cellStyle: (params) => ({
            ...ADMIN_STATUS[params.value]?.style,
            "font-weight": "bold",
          }),

          headerName: "Durum",
          width: 100,
          suppressSizeToFit: true,
        },
        {
          field: "name",
          headerName: "Ad",
        },
        {
          field: "lastname",
          headerName: "Soyad",
        },
        {
          field: "username",
          headerName: "Kullanıcı Adı",
        },
        {
          field: "email",
          headerName: "E-Posta Adresi",
        },
        {
          field: "role",
          headerName: "Yetki",
        },
        {
          field: "departmanRole",
          cellRenderer: (params) => {
            return this.$store.state.ceviri[params.value];
          },
          headerName: "Departman",
        },
        {
          field: "registered",
          cellRenderer: (params) => {
            return moment(new Date(params.value)).format("DD.MM.YYYY - HH:mm");
          },
          headerName: "Kayıt Tarihi",
        },
      ],
      defaultColDef: {
        // Varsayılan bir sütun tanımı
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [], // Verilerin ekleneceği dizi
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak bileşenler
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
            text: "asdas",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },

      // YÖNETİCİ EKLEME GÜNCELLEME MODAL
      adminModalAddUpdate: {
        modal: false,
        title: "",
        process: "",
        btnDisable: false,
        fields: {
          name: {
            options: {
              min: 2,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          lastname: {
            options: {
              min: 2,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          username: {
            options: {
              min: 3,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          email: {
            options: {
              min: 5,
              max: 100,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          password: {
            options: {
              min: 6,
              max: 25,
              shake: false,
            },
            valid: {
              value: null,
              text: "",
            },
          },
          status: [
            {
              value: "Active",
              label: "Aktif",
            },
            {
              value: "InActive",
              label: "Pasif",
            },
            {
              value: "Pending",
              label: "Beklemede",
            },
            {
              value: "Banned",
              label: "Engellendi",
            },
            {
              value: "Deleted",
              label: "Silindi",
            },
          ],
          departmanRole: [
            {
              value: "HumanResources",
              label: "İnsan Kaynakları",
            },
            {
              value: "CallCenter",
              label: "Çağrı Merkezi",
            },
            {
              value: "Marketing",
              label: "Pazarlama",
            },
            {
              value: "Developer",
              label: "Yazılım",
            },
            {
              value: "CustomerExperience",
              label: "Müşteri Deneyimi",
            },
            {
              value: "OfficeStaff",
              label: "Ofis Çalışan",

            },
          ],
          role: [],
        },
        form: {
          _id: "",
          sid: "",
          name: "",
          lastname: "",
          username: "",
          email: "",
          password: "",
          status: "",
          auth: "",
          role: "",
          departmanRole: "",
        },
      },

      // ADMIN ŞİFRE GÜNCELLEME MODAL
      adminModalUpdatePassword: {
        modal: false,
        title: "",
        btnDisable: false,
      },
    };
  },
  created() {
    this.getRolesForAuth();
    this.getAdmins();
  },
  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    },

    cleanAdminModalAddUpdateForm() {
      this.adminModalAddUpdate.fields.name.valid.value = null;
      this.adminModalAddUpdate.fields.name.valid.text = "";
      this.adminModalAddUpdate.fields.lastname.valid.value = null;
      this.adminModalAddUpdate.fields.lastname.valid.text = "";
      this.adminModalAddUpdate.fields.username.valid.value = null;
      this.adminModalAddUpdate.fields.username.valid.text = "";
      this.adminModalAddUpdate.fields.email.valid.value = null;
      this.adminModalAddUpdate.fields.email.valid.text = "";
      this.adminModalAddUpdate.fields.password.valid.value = null;
      this.adminModalAddUpdate.fields.password.valid.text = "";

      this.adminModalAddUpdate.form._id = "";
      this.adminModalAddUpdate.form.sid = "";
      this.adminModalAddUpdate.form.name = "";
      this.adminModalAddUpdate.form.lastname = "";
      this.adminModalAddUpdate.form.username = "";
      this.adminModalAddUpdate.form.email = "";
      this.adminModalAddUpdate.form.password = "";
      this.adminModalAddUpdate.form.status = "";
      this.adminModalAddUpdate.form.role = "";
      this.adminModalAddUpdate.form.departmanRole = "";
    },

    getAdmins() {
      this.$Progress.start();

      axios
        .get(process.env.VUE_APP_API_URL + "admin/admins/?userType=admin")
        .then((response) => {
          this.getRolesForAuth();
          const roles = this.adminModalAddUpdate.fields.role;
          this.rowData = _.reverse(
            response.data.adminList.map((admin) => {
              admin.role = roles.find((r) => r.value == admin.role)?.label || admin.role;
              return admin;
            })
          );
          this.$Progress.finish();
        });
    },
    getRolesForAuth() {
      this.$Progress.start();

      axios
        .get(process.env.VUE_APP_API_URL + "admin/role")
        .then((response) => {
          const auth = response.data.map((d) => {
            return {
              label: d.name,
              value: d.name.turkishToUpperAndJoin("_"),
            };
          });
          this.adminModalAddUpdate.fields.role = [
            ...auth,
            { label: "Tam Yetki", value: "SUPERUSER" },
            // { label: "Acenta", value: "AGENCY" },
          ];
          this.$Progress.finish();
        })
        .catch();
    },
    adminAddUpdate() {
      let params = {
        _id: this.adminModalAddUpdate.form._id,
        sid: this.adminModalAddUpdate.form.sid,
        name: this.adminModalAddUpdate.form.name,
        lastname: this.adminModalAddUpdate.form.lastname,
        username: this.adminModalAddUpdate.form.username,
        email: this.adminModalAddUpdate.form.email,
        status: this.adminModalAddUpdate.form.status,
        role: this.adminModalAddUpdate.form.role,
        departmanRole: this.adminModalAddUpdate.form.departmanRole,
      };
      if (this.adminModalAddUpdate.process == "put") {
        params.status =
          Object.keys(ADMIN_STATUS).find(
            (a) =>
              ADMIN_STATUS[a].value === this.adminModalAddUpdate.form.status &&
              a
          ) || this.adminModalAddUpdate.form.status;

        params.role =
          this.adminModalAddUpdate.fields.role.find(
            (a) => a.label === this.adminModalAddUpdate.form.role && a.value
          ) || this.adminModalAddUpdate.form.role;

        params.role =
          this.adminModalAddUpdate.fields.role.find(
            (a) => a.label === this.adminModalAddUpdate.form.role
          )?.value || this.adminModalAddUpdate.form.role;
      }

      if (this.adminModalAddUpdate.process === "post")
        params.password = this.adminModalAddUpdate.form.password;

      this.$Progress.start();
      this.adminModalAddUpdate.btnDisable = true; // Modal'daki post button'u disable ettik.
      axios[this.adminModalAddUpdate.process](
        process.env.VUE_APP_API_URL + "admin/admins",
        params,
        { withCredentials: true }
      ).then((response) => {
        this.$Progress.finish();
        this.adminModalAddUpdate.btnDisable = false; // Modal'daki post button'u enable ettik.
        if (response.data.result === "success") {
          this.getAdmins();
          this.adminModalAddUpdate.modal = false; // Modal'ı kapattık

          Vue.swal({
            icon: "success",
            title:
              this.adminModalAddUpdate.process === "post"
                ? "<strong>" +
                params.name +
                " " +
                params.lastname +
                "</strong> isimli yönetici başarıyla eklendi."
                : "<strong>" +
                params.name +
                " " +
                params.lastname +
                "</strong> isimli yönetici başarıyla düzenlendi.",
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Vue.swal.stopTimer);
              toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
            },
          });
        } else {
          switch (response.data.errCode) {
            case "USERNAME_IS_REGISTERED":
              Vue.swal({
                icon: "error",
                title: "Yeni Yönetici Ekle",
                html:
                  "<strong>" +
                  response.data.username +
                  "</strong> kullanıcı adı zaten kayıtlı!",
                confirmButtonText: "Tamam",
              });
              break;
            case "COULD_NOT_ADD_ADMIN":
              Vue.swal({
                icon: "error",
                title: "Yeni Yönetici Ekle",
                html: "Yönetici eklenemedi!",
                confirmButtonText: "Tamam",
              });
              break;
          }
        }
      });
    },

    adminDelete(_id) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu yöneticiyi silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            try {
              axios.delete(
                process.env.VUE_APP_API_URL + "admin/admins/" + _id,
                { withCredentials: true }
              );
              this.$Progress.finish();

              this.getAdmins();
              this.adminModalAddUpdate.modal = false; // Modal'ı kapattık
              this.$store.dispatch({
                type: "successSwal",
                text: "Yönetici başarılı bir şekilde silindi",
                refresh: false,
              });
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    adminPassword() {
      this.$Progress.start();
      this.adminModalUpdatePassword.btnDisable = true; // Modal'daki post button'u disable ettik.

      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/admins/",
          {
            _id: this.adminModalAddUpdate.form._id,
            password: this.adminModalUpdatePassword.password,
          },
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish();
          this.adminModalUpdatePassword.btnDisable = false; // Modal'daki post button'u enable ettik.

          if (response.data.result === "success") {
            this.adminModalUpdatePassword.modal = false;

            Vue.swal({
              icon: "success",
              title: "Şifre başarıyla değiştirildi.",
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Vue.swal.stopTimer);
                toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
              },
            });
          } else {
            switch (response.data.errCode) {
              case "UPDATE_ADMIN_INFO_FAILED":
                Vue.swal({
                  icon: "error",
                  title: "Şifre Değiştir",
                  html: "Şifre değiştirilemedi!",
                  confirmButtonText: "Tamam",
                });
                break;
            }
          }
        });
    },
    onRowDoubleClicked(params) {
      var _this = this;
      _this.cleanAdminModalAddUpdateForm();

      _this.adminModalAddUpdate.process = "put";
      _this.adminModalAddUpdate.title = "Yönetici Düzenle";

      _this.adminModalAddUpdate.form._id = params.node.data._id;
      _this.adminModalAddUpdate.form.sid = params.node.data.sid;
      _this.adminModalAddUpdate.form.name = params.node.data.name;
      _this.adminModalAddUpdate.form.lastname = params.node.data.lastname;
      _this.adminModalAddUpdate.form.username = params.node.data.username;
      _this.adminModalAddUpdate.form.email = params.node.data.email;
      _this.adminModalAddUpdate.form.status =
        ADMIN_STATUS[params.node.data.status]?.value || params.node.data.status;
      _this.adminModalAddUpdate.form.auth = params.node.data.auth;
      _this.adminModalAddUpdate.form.role =
        ADMIN_DEFAULT_ROLES[params.node.data.role]?.value ||
        params.node.data.role;
      _this.adminModalAddUpdate.form.departmanRole = params.node.data.departmanRole;
      _this.adminModalAddUpdate.modal = true;
    },
  },
};
</script>

